import { io } from 'socket.io-client'
const dev = !(process.env.NODE_ENV === 'production')
const baseURL = dev ? 'http://localhost:3004' : 'https://anime777.ru'
const WS_URL = process.env.WS_URL || baseURL

// import Vue from 'vue'
const socket = io(WS_URL, { transports: [ 'websocket', 'polling' ] })
// Vue.prototype.$socket = socket

export default ({ app }, inject) => {
  inject('socket', socket)
}
