import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _06f13efe = () => interopDefault(import('../pages/about-anime.vue' /* webpackChunkName: "pages/about-anime" */))
const _07034417 = () => interopDefault(import('../pages/adblock.vue' /* webpackChunkName: "pages/adblock" */))
const _09108a17 = () => interopDefault(import('../pages/amv.vue' /* webpackChunkName: "pages/amv" */))
const _21e61a58 = () => interopDefault(import('../pages/anime-new.vue' /* webpackChunkName: "pages/anime-new" */))
const _0dda86ee = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _6baff2b8 = () => interopDefault(import('../pages/apps/index.vue' /* webpackChunkName: "pages/apps/index" */))
const _14646007 = () => interopDefault(import('../pages/avatars.vue' /* webpackChunkName: "pages/avatars" */))
const _c3e5cebe = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _c1dedf20 = () => interopDefault(import('../pages/characters/index.vue' /* webpackChunkName: "pages/characters/index" */))
const _7fb0444a = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _c2b6ec92 = () => interopDefault(import('../pages/cinema/index.vue' /* webpackChunkName: "pages/cinema/index" */))
const _85c91904 = () => interopDefault(import('../pages/comics/index.vue' /* webpackChunkName: "pages/comics/index" */))
const _a7aee612 = () => interopDefault(import('../pages/comments.vue' /* webpackChunkName: "pages/comments" */))
const _6740bc58 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _4c6bfbda = () => interopDefault(import('../pages/cp1602/index.vue' /* webpackChunkName: "pages/cp1602/index" */))
const _5ca02007 = () => interopDefault(import('../pages/device-detect.vue' /* webpackChunkName: "pages/device-detect" */))
const _6abd1bb5 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _d3bd069c = () => interopDefault(import('../pages/donate1/index.vue' /* webpackChunkName: "pages/donate1/index" */))
const _e2542596 = () => interopDefault(import('../pages/download-anime/index.vue' /* webpackChunkName: "pages/download-anime/index" */))
const _c6fff54e = () => interopDefault(import('../pages/expoints.vue' /* webpackChunkName: "pages/expoints" */))
const _1e6de900 = () => interopDefault(import('../pages/fundoms/index.vue' /* webpackChunkName: "pages/fundoms/index" */))
const _8335ab5e = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _c5e61f9c = () => interopDefault(import('../pages/gifs.vue' /* webpackChunkName: "pages/gifs" */))
const _3cba63d0 = () => interopDefault(import('../pages/memes.vue' /* webpackChunkName: "pages/memes" */))
const _71fac7f5 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _f3398fa4 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _21abb1fa = () => interopDefault(import('../pages/pokemons.vue' /* webpackChunkName: "pages/pokemons" */))
const _568523f5 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _60808126 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _06422ff6 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _00035f3a = () => interopDefault(import('../pages/promocodes.vue' /* webpackChunkName: "pages/promocodes" */))
const _032399f4 = () => interopDefault(import('../pages/random.vue' /* webpackChunkName: "pages/random" */))
const _bd5bb420 = () => interopDefault(import('../pages/ranobes/index.vue' /* webpackChunkName: "pages/ranobes/index" */))
const _742b02e0 = () => interopDefault(import('../pages/rating.vue' /* webpackChunkName: "pages/rating" */))
const _02bb3703 = () => interopDefault(import('../pages/referrals.vue' /* webpackChunkName: "pages/referrals" */))
const _0afe9781 = () => interopDefault(import('../pages/schedule-anime.vue' /* webpackChunkName: "pages/schedule-anime" */))
const _659e8d2a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4bbccc6e = () => interopDefault(import('../pages/store.vue' /* webpackChunkName: "pages/store" */))
const _5a2391d7 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _9fdbad60 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _1f8c90c2 = () => interopDefault(import('../pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _07068260 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _4aa29a8d = () => interopDefault(import('../pages/term-of-service.vue' /* webpackChunkName: "pages/term-of-service" */))
const _20ec9a76 = () => interopDefault(import('../pages/tv.vue' /* webpackChunkName: "pages/tv" */))
const _9941f04c = () => interopDefault(import('../pages/updates/index.vue' /* webpackChunkName: "pages/updates/index" */))
const _79ea58d8 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _2013420f = () => interopDefault(import('../pages/vpn_block.vue' /* webpackChunkName: "pages/vpn_block" */))
const _291dcc74 = () => interopDefault(import('../pages/wallpapers.vue' /* webpackChunkName: "pages/wallpapers" */))
const _6c7a02df = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _02c098a1 = () => interopDefault(import('../pages/apps/battles.vue' /* webpackChunkName: "pages/apps/battles" */))
const _0e4f2570 = () => interopDefault(import('../pages/apps/puzzle.vue' /* webpackChunkName: "pages/apps/puzzle" */))
const _25dcd56c = () => interopDefault(import('../pages/apps/puzzle15.vue' /* webpackChunkName: "pages/apps/puzzle15" */))
const _d5d8df42 = () => interopDefault(import('../pages/apps/quiz.vue' /* webpackChunkName: "pages/apps/quiz" */))
const _22f38ee5 = () => interopDefault(import('../pages/cp1602/articles.vue' /* webpackChunkName: "pages/cp1602/articles" */))
const _905b3ece = () => interopDefault(import('../pages/cp1602/blogs.vue' /* webpackChunkName: "pages/cp1602/blogs" */))
const _33f7db00 = () => interopDefault(import('../pages/cp1602/chat.vue' /* webpackChunkName: "pages/cp1602/chat" */))
const _be8dccce = () => interopDefault(import('../pages/cp1602/cinema.vue' /* webpackChunkName: "pages/cp1602/cinema" */))
const _75f8a4a0 = () => interopDefault(import('../pages/cp1602/comics.vue' /* webpackChunkName: "pages/cp1602/comics" */))
const _69dfe71c = () => interopDefault(import('../pages/cp1602/comments.vue' /* webpackChunkName: "pages/cp1602/comments" */))
const _660b8d8e = () => interopDefault(import('../pages/cp1602/desc.vue' /* webpackChunkName: "pages/cp1602/desc" */))
const _472e8217 = () => interopDefault(import('../pages/cp1602/donate.vue' /* webpackChunkName: "pages/cp1602/donate" */))
const _5aea77a6 = () => interopDefault(import('../pages/cp1602/feedback.vue' /* webpackChunkName: "pages/cp1602/feedback" */))
const _7acadd10 = () => interopDefault(import('../pages/cp1602/fundoms.vue' /* webpackChunkName: "pages/cp1602/fundoms" */))
const _6fdc3f47 = () => interopDefault(import('../pages/cp1602/kino.vue' /* webpackChunkName: "pages/cp1602/kino" */))
const _1cc85741 = () => interopDefault(import('../pages/cp1602/links.vue' /* webpackChunkName: "pages/cp1602/links" */))
const _09694157 = () => interopDefault(import('../pages/cp1602/logs.vue' /* webpackChunkName: "pages/cp1602/logs" */))
const _22575ea3 = () => interopDefault(import('../pages/cp1602/reviews.vue' /* webpackChunkName: "pages/cp1602/reviews" */))
const _ef3cded4 = () => interopDefault(import('../pages/cp1602/tasks.vue' /* webpackChunkName: "pages/cp1602/tasks" */))
const _37c4ccaa = () => interopDefault(import('../pages/cp1602/tv.vue' /* webpackChunkName: "pages/cp1602/tv" */))
const _36fd2163 = () => interopDefault(import('../pages/donate1/success.vue' /* webpackChunkName: "pages/donate1/success" */))
const _bd1209e8 = () => interopDefault(import('../pages/blogs/_id/index.vue' /* webpackChunkName: "pages/blogs/_id/index" */))
const _014bf35b = () => interopDefault(import('../pages/characters/_id/index.vue' /* webpackChunkName: "pages/characters/_id/index" */))
const _6a678ddf = () => interopDefault(import('../pages/cinema/_id.vue' /* webpackChunkName: "pages/cinema/_id" */))
const _ec1ac12e = () => interopDefault(import('../pages/comics/_id/index.vue' /* webpackChunkName: "pages/comics/_id/index" */))
const _04108e46 = () => interopDefault(import('../pages/download-anime/_id.vue' /* webpackChunkName: "pages/download-anime/_id" */))
const _033ba930 = () => interopDefault(import('../pages/fundoms/_id.vue' /* webpackChunkName: "pages/fundoms/_id" */))
const _a9a8e60e = () => interopDefault(import('../pages/games/_id.vue' /* webpackChunkName: "pages/games/_id" */))
const _35c6c8db = () => interopDefault(import('../pages/ranobes/_id/index.vue' /* webpackChunkName: "pages/ranobes/_id/index" */))
const _07ec2c1e = () => interopDefault(import('../pages/tags/_tag.vue' /* webpackChunkName: "pages/tags/_tag" */))
const _7ed3a8be = () => interopDefault(import('../pages/updates/_type.vue' /* webpackChunkName: "pages/updates/_type" */))
const _0168b140 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _3ec8964a = () => interopDefault(import('../pages/watch/_id/index.vue' /* webpackChunkName: "pages/watch/_id/index" */))
const _9ef41ba2 = () => interopDefault(import('../pages/characters/_id/art/index.vue' /* webpackChunkName: "pages/characters/_id/art/index" */))
const _78c7caf2 = () => interopDefault(import('../pages/characters/_id/cosplay/index.vue' /* webpackChunkName: "pages/characters/_id/cosplay/index" */))
const _60c83527 = () => interopDefault(import('../pages/comics/_id/chapter/index.vue' /* webpackChunkName: "pages/comics/_id/chapter/index" */))
const _164666ce = () => interopDefault(import('../pages/ranobes/_id/chapter/index.vue' /* webpackChunkName: "pages/ranobes/_id/chapter/index" */))
const _abed15b6 = () => interopDefault(import('../pages/watch/_id/characters/index.vue' /* webpackChunkName: "pages/watch/_id/characters/index" */))
const _fd4ac0ce = () => interopDefault(import('../pages/comics/_id/chapter/_ch.vue' /* webpackChunkName: "pages/comics/_id/chapter/_ch" */))
const _6c326b8b = () => interopDefault(import('../pages/ranobes/_id/chapter/_ch.vue' /* webpackChunkName: "pages/ranobes/_id/chapter/_ch" */))
const _605d8226 = () => interopDefault(import('../pages/blogs/_id/_article.vue' /* webpackChunkName: "pages/blogs/_id/_article" */))
const _927cc842 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-anime",
    component: _06f13efe,
    name: "about-anime"
  }, {
    path: "/adblock",
    component: _07034417,
    name: "adblock"
  }, {
    path: "/amv",
    component: _09108a17,
    name: "amv"
  }, {
    path: "/anime-new",
    component: _21e61a58,
    name: "anime-new"
  }, {
    path: "/app",
    component: _0dda86ee,
    name: "app"
  }, {
    path: "/apps",
    component: _6baff2b8,
    name: "apps"
  }, {
    path: "/avatars",
    component: _14646007,
    name: "avatars"
  }, {
    path: "/blogs",
    component: _c3e5cebe,
    name: "blogs"
  }, {
    path: "/characters",
    component: _c1dedf20,
    name: "characters"
  }, {
    path: "/chat",
    component: _7fb0444a,
    name: "chat"
  }, {
    path: "/cinema",
    component: _c2b6ec92,
    name: "cinema"
  }, {
    path: "/comics",
    component: _85c91904,
    name: "comics"
  }, {
    path: "/comments",
    component: _a7aee612,
    name: "comments"
  }, {
    path: "/copyright",
    component: _6740bc58,
    name: "copyright"
  }, {
    path: "/cp1602",
    component: _4c6bfbda,
    name: "cp1602"
  }, {
    path: "/device-detect",
    component: _5ca02007,
    name: "device-detect"
  }, {
    path: "/donate",
    component: _6abd1bb5,
    name: "donate"
  }, {
    path: "/donate1",
    component: _d3bd069c,
    name: "donate1"
  }, {
    path: "/download-anime",
    component: _e2542596,
    name: "download-anime"
  }, {
    path: "/expoints",
    component: _c6fff54e,
    name: "expoints"
  }, {
    path: "/fundoms",
    component: _1e6de900,
    name: "fundoms"
  }, {
    path: "/games",
    component: _8335ab5e,
    name: "games"
  }, {
    path: "/gifs",
    component: _c5e61f9c,
    name: "gifs"
  }, {
    path: "/memes",
    component: _3cba63d0,
    name: "memes"
  }, {
    path: "/notifications",
    component: _71fac7f5,
    name: "notifications"
  }, {
    path: "/partners",
    component: _f3398fa4,
    name: "partners"
  }, {
    path: "/pokemons",
    component: _21abb1fa,
    name: "pokemons"
  }, {
    path: "/policy",
    component: _568523f5,
    name: "policy"
  }, {
    path: "/popular",
    component: _60808126,
    name: "popular"
  }, {
    path: "/profile",
    component: _06422ff6,
    name: "profile"
  }, {
    path: "/promocodes",
    component: _00035f3a,
    name: "promocodes"
  }, {
    path: "/random",
    component: _032399f4,
    name: "random"
  }, {
    path: "/ranobes",
    component: _bd5bb420,
    name: "ranobes"
  }, {
    path: "/rating",
    component: _742b02e0,
    name: "rating"
  }, {
    path: "/referrals",
    component: _02bb3703,
    name: "referrals"
  }, {
    path: "/schedule-anime",
    component: _0afe9781,
    name: "schedule-anime"
  }, {
    path: "/search",
    component: _659e8d2a,
    name: "search"
  }, {
    path: "/store",
    component: _4bbccc6e,
    name: "store"
  }, {
    path: "/subscribe",
    component: _5a2391d7,
    name: "subscribe"
  }, {
    path: "/success",
    component: _9fdbad60,
    name: "success"
  }, {
    path: "/tags",
    component: _1f8c90c2,
    name: "tags"
  }, {
    path: "/team",
    component: _07068260,
    name: "team"
  }, {
    path: "/term-of-service",
    component: _4aa29a8d,
    name: "term-of-service"
  }, {
    path: "/tv",
    component: _20ec9a76,
    name: "tv"
  }, {
    path: "/updates",
    component: _9941f04c,
    name: "updates"
  }, {
    path: "/users",
    component: _79ea58d8,
    name: "users"
  }, {
    path: "/vpn_block",
    component: _2013420f,
    name: "vpn_block"
  }, {
    path: "/wallpapers",
    component: _291dcc74,
    name: "wallpapers"
  }, {
    path: "/watch",
    component: _6c7a02df,
    name: "watch"
  }, {
    path: "/apps/battles",
    component: _02c098a1,
    name: "apps-battles"
  }, {
    path: "/apps/puzzle",
    component: _0e4f2570,
    name: "apps-puzzle"
  }, {
    path: "/apps/puzzle15",
    component: _25dcd56c,
    name: "apps-puzzle15"
  }, {
    path: "/apps/quiz",
    component: _d5d8df42,
    name: "apps-quiz"
  }, {
    path: "/cp1602/articles",
    component: _22f38ee5,
    name: "cp1602-articles"
  }, {
    path: "/cp1602/blogs",
    component: _905b3ece,
    name: "cp1602-blogs"
  }, {
    path: "/cp1602/chat",
    component: _33f7db00,
    name: "cp1602-chat"
  }, {
    path: "/cp1602/cinema",
    component: _be8dccce,
    name: "cp1602-cinema"
  }, {
    path: "/cp1602/comics",
    component: _75f8a4a0,
    name: "cp1602-comics"
  }, {
    path: "/cp1602/comments",
    component: _69dfe71c,
    name: "cp1602-comments"
  }, {
    path: "/cp1602/desc",
    component: _660b8d8e,
    name: "cp1602-desc"
  }, {
    path: "/cp1602/donate",
    component: _472e8217,
    name: "cp1602-donate"
  }, {
    path: "/cp1602/feedback",
    component: _5aea77a6,
    name: "cp1602-feedback"
  }, {
    path: "/cp1602/fundoms",
    component: _7acadd10,
    name: "cp1602-fundoms"
  }, {
    path: "/cp1602/kino",
    component: _6fdc3f47,
    name: "cp1602-kino"
  }, {
    path: "/cp1602/links",
    component: _1cc85741,
    name: "cp1602-links"
  }, {
    path: "/cp1602/logs",
    component: _09694157,
    name: "cp1602-logs"
  }, {
    path: "/cp1602/reviews",
    component: _22575ea3,
    name: "cp1602-reviews"
  }, {
    path: "/cp1602/tasks",
    component: _ef3cded4,
    name: "cp1602-tasks"
  }, {
    path: "/cp1602/tv",
    component: _37c4ccaa,
    name: "cp1602-tv"
  }, {
    path: "/donate1/success",
    component: _36fd2163,
    name: "donate1-success"
  }, {
    path: "/blogs/:id",
    component: _bd1209e8,
    name: "blogs-id"
  }, {
    path: "/characters/:id",
    component: _014bf35b,
    name: "characters-id"
  }, {
    path: "/cinema/:id",
    component: _6a678ddf,
    name: "cinema-id"
  }, {
    path: "/comics/:id",
    component: _ec1ac12e,
    name: "comics-id"
  }, {
    path: "/download-anime/:id?",
    component: _04108e46,
    name: "download-anime-id"
  }, {
    path: "/fundoms/:id",
    component: _033ba930,
    name: "fundoms-id"
  }, {
    path: "/games/:id",
    component: _a9a8e60e,
    name: "games-id"
  }, {
    path: "/ranobes/:id",
    component: _35c6c8db,
    name: "ranobes-id"
  }, {
    path: "/tags/:tag",
    component: _07ec2c1e,
    name: "tags-tag"
  }, {
    path: "/updates/:type",
    component: _7ed3a8be,
    name: "updates-type"
  }, {
    path: "/users/:id",
    component: _0168b140,
    name: "users-id"
  }, {
    path: "/watch/:id",
    component: _3ec8964a,
    name: "watch-id"
  }, {
    path: "/characters/:id/art",
    component: _9ef41ba2,
    name: "characters-id-art"
  }, {
    path: "/characters/:id/cosplay",
    component: _78c7caf2,
    name: "characters-id-cosplay"
  }, {
    path: "/comics/:id/chapter",
    component: _60c83527,
    name: "comics-id-chapter"
  }, {
    path: "/ranobes/:id/chapter",
    component: _164666ce,
    name: "ranobes-id-chapter"
  }, {
    path: "/watch/:id/characters",
    component: _abed15b6,
    name: "watch-id-characters"
  }, {
    path: "/comics/:id/chapter/:ch",
    component: _fd4ac0ce,
    name: "comics-id-chapter-ch"
  }, {
    path: "/ranobes/:id/chapter/:ch",
    component: _6c326b8b,
    name: "ranobes-id-chapter-ch"
  }, {
    path: "/blogs/:id/:article",
    component: _605d8226,
    name: "blogs-id-article"
  }, {
    path: "/",
    component: _927cc842,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
