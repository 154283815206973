<template>
  <div class="cln">
    <nuxt />
    <error-component v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    errorComponent: () => import('~/components/errorComponent.vue')
  },
  computed: mapState(['error']),
}
</script>

<style lang="stylus">
.cln
  background-color: #fff1e5
</style>
